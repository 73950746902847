<template>
    <div class="top-bar">
        <div class="calendars">
            <div class="calendar-wrapper text-left">
                <label>Zamówienia od</label>
                <VueDatePicker locale="pl" cancelText="Anuluj" :enable-time-picker="false" selectText="Wybierz" v-model="dateFrom" @update:model-value="getOrders"></VueDatePicker>
            </div>
            <div class="calendar-wrapper text-left">
                <label>Zamówienia do</label>
                <VueDatePicker locale="pl" cancelText="Anuluj" :enable-time-picker="false" selectText="Wybierz" v-model="dateTo"></VueDatePicker>
            </div>
        </div>
        <div class="print-all">
            <button @click="printSelected" v-print="printListObj" v-if="listToPrint.length>0">Drukuj zaznaczone</button>
        </div>
    </div>
    <table id="orders">
        <thead>
          <tr>
              <th>ID</th>
              <th>Stolik</th>
              <th>Pozycje</th>
              <th>Płatność</th>
              <th>Kwota</th>
              <th>Data</th>
              <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
        <template v-if="orders && !loading">
          <tr v-for="order in orders" :key="order.id" >
              <td>{{order.id}}</td>
              <td>{{order.tableNo}}</td>
              <td style="text-align: left;">
                  <span style="display: block;" v-for="item in order.items" :key="item.product_id">{{ item.quantity }} x {{ item.name }}</span>
                  <div v-if="order.note" style="margin-top: 0.5rem">
                      <small style="display: block; font-weight: bold;">Dodatkowe informacje:</small><span v-html="order.note"></span>
                  </div>
              </td>
              <td>
                  {{order.payment_method_title}} <span v-if="order.payment_method === 'cheque'">&#128179;</span>
                  <span v-if="order.payment_method === 'cod'">&#128181;</span>
              </td>
              <td>{{order.total}} {{order.currency_symbol}}</td>
              <td>{{order.created_at.date}} <span class="bold">{{order.created_at.time}}</span></td>
              <td>
                  <input type="checkbox" v-model="listToPrint" :value="order"/>
                  <button @click="printOrder(order)" v-print="printObj" >Drukuj</button>
                  <span class="block" v-if="order.printed === 'Tak'" >Wydrukowano</span>
              </td>
          </tr>
        </template>
        <template v-else>
            <tr >
                <td colspan="7">
                    <span class="loader"></span>
                </td>
            </tr>
        </template>

        </tbody>
    </table>

<!--    <button v-print="printObj">Print local range</button><div id="loading" v-show="printLoading"></div>-->

    <div style="display: none;">
    <div id="printMe">
        <h1>Stolik: {{toPrint.tableNo}}</h1>
        <ul>
            <li v-for="item in toPrint.items" :key="item.product_id">{{ item.quantity }} x {{ item.name }}</li>
        </ul>
        <p>
            {{toPrint.payment_method_title}} <span v-if="toPrint.payment_method === 'cheque'">&#128179;</span>
            <span v-if="toPrint.payment_method === 'cod'">&#128181;</span>
        </p>
        <p>{{toPrint.note}}</p>
    </div>
    </div>
    <div style="display: none;">
        <div id="printListMe">
            <template v-for="or in listToPrint" :key="or.id">
                <h1>Stolik: {{or.tableNo}}</h1>
                <ul>
                    <li v-for="item in or.items" :key="item.product_id">{{ item.quantity }} x {{ item.name }}</li>
                </ul>
                <p>
                    {{or.payment_method_title}} <span v-if="or.payment_method === 'cheque'">&#128179;</span>
                    <span v-if="or.payment_method === 'cod'">&#128181;</span>
                </p>
                <p>{{or.note}}</p>
            </template>
        </div>
    </div>

</template>

<script setup>
  import axios from "axios";

  import {useToast} from 'vue-toast-notification';
  import 'vue-toast-notification/dist/theme-sugar.css';

  import {onBeforeUnmount, onMounted, ref} from "vue";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  const $toast = useToast();
  const toPrint = ref({});
  const listToPrint = ref([]);

  const loading = ref(true);

  const dateFrom = ref(null);
  const dateTo = ref(null);
  const formatDateTime = (isoDateTime) => {
      const date = new Date(isoDateTime);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return {time: `${hours}:${minutes}`, date: `${day}.${month}.${year}`};
  }


  const printLoading = ref(false);
  const printObj = {
      id: "printMe",
      popTitle: 'Harasówka',
      extraHead: '<meta http-equiv="Content-Language" content="pl-PL"/>',
      beforeOpenCallback () {
          console.log();
          printLoading.value = true;
          // console.log('打开之前')
      },
      openCallback () {
          printLoading.value = false
              // console.log('执行了打印')
      },
      closeCallback () {
          // console.log('关闭了打印工具')
      }
  }
  const printListObj = {
      id: "printListMe",
      popTitle: 'Harasówka',
      extraHead: '<meta http-equiv="Content-Language" content="pl-PL"/>',
      beforeOpenCallback () {
          console.log();
          printLoading.value = true;
          // console.log('打开之前')
      },
      openCallback () {
          printLoading.value = false
          // console.log('执行了打印')
      },
      closeCallback () {
          // console.log('关闭了打印工具')
          listToPrint.value = [];
      }
  }

  const orders = ref([]);
  const refreshInterval = ref(null);
  const url = 'https://sklephara.appworksdev.pl/wp-json/wc/v3/orders';
  const apiKey = 'ck_bf13b358ed8cd4f8049656599d754647102f208a';
  const apiSecret = 'cs_3532b1ac42b87a037c8560b86ac8a39050860868';
  const authHeader = `Basic ${btoa(`${apiKey}:${apiSecret}`)}`;

  const headers = {
      Authorization: authHeader,
  };


  const getOrders = () => {
      loading.value = true;
      let orders_url = url+'?per_page=100';
      if(dateFrom.value){
          let afterDate = dateFrom.value;
          afterDate.setHours(0);
          afterDate.setMinutes(59);
          let after = afterDate.toISOString();
          orders_url += '&after='+after;
      }
      if(dateTo.value){
          let beforeDate = dateTo.value;
          beforeDate.setHours(23);
          beforeDate.setMinutes(59);
          let before = beforeDate.toISOString();
          orders_url += '&before='+before;
      }

      axios.get(orders_url, { headers })
          .then(response => {
              orders.value = response.data.map((item)=>{
                  let tableNo = item.meta_data.filter((item)=> { if(item.key === '_billing_numer_stolika') return item});
                  let printed = item.meta_data.filter((item)=> {if(item.key === '_order_wydrukowano') return item});
                  console.log(printed);
                  return {id: item.id, tableNo: tableNo[0].value, total: item.total,
                      note: item.customer_note,
                      currency_symbol: item.currency_symbol,
                      payment_method: item.payment_method,
                      payment_method_title: item.payment_method_title,
                      items: item.line_items,
                      printed: printed.length>0?printed[0].value:[],
                      created_at: formatDateTime(item.date_created)
                  }
              });
              loading.value = false;
          })
          .catch(error => {
              console.error(error);
          });
  }

  const printOrder = async (order) => {
      toPrint.value = order;
      const requestData = {
          meta_data: [{
              "id": 5762,
              "key": "_order_wydrukowano",
              "value": "Tak"
          }],
      };
      const response = await axios.put(url+'/'+order.id, requestData, {
          headers: {
              'Authorization': authHeader,
              'Content-Type': 'application/json',
          }});

      if(response){
        $toast.success('Zlecono wydruk!');
          getOrders();
      }
  }

  const printSelected = async () => {
      listToPrint.value.forEach((i) => {
          const requestData = {
              meta_data: [{
                  "id": 5762,
                  "key": "_order_wydrukowano",
                  "value": "Tak"
              }],
          };
          const response = axios.put(url+'/'+i.id, requestData, {
              headers: {
                  'Authorization': authHeader,
                  'Content-Type': 'application/json',
              }});

          if(response){
              $toast.success('Zlecono wydruk!');
          }
      });
      // listToPrint.value = [];
      getOrders();
  }
  onMounted(()=>{
      dateFrom.value = new Date();
      dateTo.value = new Date();
      getOrders();
      refreshInterval.value = setInterval(() => {
          getOrders();
      }, 60000);
  });
  onBeforeUnmount(()=>{
      clearInterval(refreshInterval);
  })
</script>
<style>
#orders {
    /*font-family: Arial, Helvetica, sans-serif;*/
    border-collapse: collapse;
    width: 100%;
}

#orders td, #orders th {
    border: 1px solid #ddd;
    padding: 8px;
}

#orders tr:nth-child(even){background-color: #f2f2f2;}

#orders tr:hover {background-color: #ddd;}

#orders th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #796A58;
    color: white;
}
#orders td button {
    background-color: #796A58; color: #fff; border: none;
}
button { background-color: #796A58; color: #fff; border: none; }
.bold {font-weight: bold;}
.block {display: block;}

.top-bar {display: flex; gap: 1rem; margin-bottom: 2rem; justify-content: space-between; align-items: flex-end;}
.calendar-wrapper {
    max-width: 16rem;
}
.text-left {text-align: left;}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #796A58;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.calendars {
    display: flex; gap: 1rem;
}
.print-all {justify-items: flex-end;}
.print-all button {padding: 0.5rem 1rem;}
</style>
